import React from "react";
import AemIntroCarouselStyles from "./AemIntroCarousel.module.scss";
import "./AemIntroCarousel.scss";
import HoCarousel from "../../Caroursel";
import ContactPopUp from "../../ContactPopUp";

function aemIntroCarousel(props) {
  const { url } = props;

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div style={{ position: "relative" }}>
      <HoCarousel
        showIndicators={false}
        showStatus={false}
        showArrows={false}
        infiniteLoop={true}
        autoPlay={true}
        stopOnHover={true}
        showThumbs={false}
        transitionTime={800}
        interval={5000}
        className={`aemIntroCarousel`}
        swipeable={false}
      >
        <div className={AemIntroCarouselStyles.carouselWrapper}>
          <p>
            AEM <span>- A Powerhouse of</span>
          </p>
          <p>Digital Experience Capabilities</p>
          <p>
            Combine powerful authoring capabilities with Analytics, Target and
            Campaign to present a highly personalised cross-channel customer
            experience.
          </p>
          <button onClick={openModal}>
            REQUEST DEMO{" "}
            <span>
              <img src="../RedVector.svg"></img>
            </span>
          </button>
        </div>
      </HoCarousel>
      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let’s Connect"
        subTitle="Fill out this short form, and we’ll get back to you within one
        working day."
        url={url}
      />
    </div>
  );
}

export default aemIntroCarousel;
