import React from "react";
import ContactStyles from "./Contact.module.scss";
import ContactPopUp from "../ContactPopUp";

function ContactUs(props) {
  const { url = '' } = props;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className={ContactStyles.ContactStylesWrapper}>
      <div className={`${ContactStyles.learnMoreSection}`}>
        <div className={`${ContactStyles.learnMoreContents}`}>
          <h4>Would you like to learn more about our services?</h4>
          <p onClick={openModal}>
            Let's connect
            <span>
              <img src="../../RedVector.svg"></img>
            </span>
          </p>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let’s Connect"
        subTitle="Fill out this short form, and we’ll get back to you within one
        working day."
        url={url}
        section="Aem contactus"
      />
    </div>
  );
}

export default ContactUs;
