import React from "react";
import Layout from "../components/Layout";
import AemIntroCarousel from "../components/AemCarousel/AemIntroCarousel";
import AemLandingStyles from "./aem-landing.module.scss";
import ServiceAndPartnership from "../components/ServiceAndPartnership";
import AemResourceCarousel from "../components/AemCarousel/AemResourceCarousel/AemResourceCarousel";
import AemTestimonialCarousel from "../components/AemCarousel/AemTestimonialCarousel";
import ContactUs from "../components/ContactUs";
import ToolsAndAccelerator from "../components/ToolsSection";

function aem({ location }) {
  const url = location.href;

  return (
    <Layout location={location}>
      <div className={AemLandingStyles.container}>
        <div className={AemLandingStyles.introductionContainer}>
          <AemIntroCarousel url={url}/>
          <div className={AemLandingStyles.circleWrapper}>
            <div className={AemLandingStyles.circle1}>
              <span className={AemLandingStyles.circle1ImageWrapper}>
                <img src="../../circle-icon1.svg"></img>
              </span>
            </div>
            <div className={AemLandingStyles.circle2}>
              <span className={AemLandingStyles.circle2ImageWrapper}>
                <img src="../../circle-icon2.png"></img>
              </span>

              <span className={AemLandingStyles.circle3ImageWrapper}>
                <img src="../../circle-icon3.svg"></img>
              </span>
            </div>
            <div className={AemLandingStyles.circle3}></div>
          </div>
        </div>
        <div className={AemLandingStyles.showcaseWrapper}>
          <h4>See how Adobe Experience Manager</h4>
          <h4> can revolutionize your customer experience</h4>
          <div className={AemLandingStyles.showcaseItemWrapper}>
            <div className={AemLandingStyles.showcaseItems}>
              <div className={AemLandingStyles.showcaseTitle}>
                <img src="../../cms.svg"></img>
                <p>Content Management System</p>
              </div>
              <div className={AemLandingStyles.showcaseBody}>
                <ul>
                  <li>
                    Accelerates website creation and deployment with reusable
                    components.
                  </li>
                  <li>
                    Publish once and deliver at scale with consistent cross
                    channel experiences.
                  </li>
                  <li>
                    Make changes to the website efficient with Experience
                    Fragments.
                  </li>
                  <li>Ensure compliance and collaboration with workflows.</li>
                  <li>
                    Push content into print, apps, social media, and all other
                    platforms through rich APIs.
                  </li>
                </ul>
              </div>
            </div>
            <div className={AemLandingStyles.showcaseItems}>
              <div className={AemLandingStyles.showcaseTitle}>
                <img src="../../assets-dam.svg"></img>
                <p>Assets/DAM</p>
              </div>
              <div className={AemLandingStyles.showcaseBody}>
                <ul>
                  <li>
                    Reuse assets effortlessly with the built in assets panel.
                  </li>
                  <li>Search assets faster with tags and metadata.</li>
                  <li>Ensure governance on branded and copyrighted content.</li>
                  <li>
                    Encourage asset sharing among coworkers, business units and
                    partners and reduce creative costs.
                  </li>
                </ul>
              </div>
            </div>
            <div className={AemLandingStyles.showcaseItems}>
              <div className={AemLandingStyles.showcaseTitle}>
                <img src="../../forms.svg"></img>
                <p>Forms</p>
              </div>
              <div className={AemLandingStyles.showcaseBody}>
                <ul>
                  <li>
                    Go paperless with light-responsive forms that makes
                    enrolment easy on any channel.
                  </li>
                  <li>Reduce time to completion by pre-filling forms.</li>
                  <li>
                    Improve accuracy with real-time changes to forms based on
                    user input.
                  </li>
                  <li>Save information loss with auto-save.</li>
                  <li>Leverage Forms Workflow automate followup actions.</li>
                </ul>
              </div>
            </div>
            <div className={AemLandingStyles.showcaseItems}>
              <div className={AemLandingStyles.showcaseTitle}>
                <img src="../../insights.svg"></img>
                <p>Insights and Personalization</p>
              </div>
              <div className={AemLandingStyles.showcaseBody}>
                <ul>
                  <li>
                    <b>Adobe Analytics</b> - Get more than vanity metrics with
                    customer journey analytics, versatile reporting and predictive
                    intelligence.
                  </li>
                  <li>
                    <b>Adobe Target</b> - Introduce personalization by
                    automatically segmenting visitors and sending them to
                    interesting and relevant content at scale.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ServiceAndPartnership url={url} />
        <ToolsAndAccelerator url={url} />
        <AemTestimonialCarousel />
        <AemResourceCarousel />
        <div className={AemLandingStyles.clientWrapper}>
          <h4>Our Clients and Partners</h4>
          <div className={AemLandingStyles.imageWrapper}>
            <img src="../../Adobe.svg"></img>
            <img src="../../Citrix-black.svg"></img>
            <img src="../../Straumann.svg"></img>
            <img src="../../podio.svg"></img>
            <img src="../../google.svg"></img>
          </div>
        </div>
        <ContactUs url={url}/>
      </div>
    </Layout>
  );
}

export default aem;
