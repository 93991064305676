import React from "react";
import ToolsAndAcceleratorStyles from "./ToolsAndAccelerator.module.scss";
import ContactPopUp from "../ContactPopUp";

function ToolsAndAcceleration(props) {
  const { url = '' } = props;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className={ToolsAndAcceleratorStyles.toolsWrapper}>
      <h4>Tools & Accelerators</h4>
      <div className={ToolsAndAcceleratorStyles.toolsCardWrapper}>
        <div className={ToolsAndAcceleratorStyles.toolsCard}>
          <div className={ToolsAndAcceleratorStyles.cardImageWrapper}>
            <img src="../../aem-tool.svg"></img>
          </div>
          <p>AEM & Salesforce Knowledge Connector</p>
          <p>
            Leverage your Marketing Digital Experience tools and assets to drive
            a consistent support self-service experience.
            {/**<span>
              &nbsp;
              <a href="#">Learn More</a>
            </span>{" "} */}
          </p>
          {/**<p onClick={openModal}
          >
            Request Demo
            <span>
              <img src="../../RedVector.svg"></img>
            </span>
          </p> */}
        </div>
        <div className={ToolsAndAcceleratorStyles.toolsCard}>
          <div className={ToolsAndAcceleratorStyles.cardImageWrapper}>
            <img src="../../comprehensive-tool.svg"></img>
          </div>
          <p>Comprehensive Event framework</p>
          <p>
            Advanced event tracking framework that just plugs with your AEM
            instance to provide marketers the insights they need to improve
            campaign and product page performance.
            {/**<span>
              &nbsp;
              <a href="#">Learn More</a>
            </span>{" "} */}
          </p>
          {/**<p
            onClick={openModal}
          >
            Request Demo
            <span>
              <img src="../../RedVector.svg"></img>
            </span>
          </p> */}
        </div>
        <div className={ToolsAndAcceleratorStyles.toolsCard}>
          <div className={ToolsAndAcceleratorStyles.cardImageWrapper}>
            <img src="../../broken-link-tool.svg"></img>
          </div>
          <p>Broken Links Checker</p>
          <p>
            Fix broken links before your customer finds them; With this tool,
            marketers can go from signup to detailed, actionable scan reports in
            5 minutes while opting to get the reports on a regular interval.
            {/**<span>
              &nbsp;
              <a href="#">Learn More</a>
            </span>{" "} */}
          </p>
          {/**<button
            className={ToolsAndAcceleratorStyles.requestButton}
            onClick={openModal}
          >
            request demo
          </button> */}
        </div>
      </div>
      <div className={ToolsAndAcceleratorStyles.borderSeparator}></div>
      <ContactPopUp
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        section="Tools and Accelerator"
        page="support-tools-portals"
        url={url}
      />
    </div>
  );
}

export default ToolsAndAcceleration;
