import React from "react";
import HoCarousel from "../../Caroursel";
import TestimonialCarouselStyles from "./AemTestimonialCarousel.module.scss";
import "./AemTestimonialCarousel.scss";

function AemTestimonialCarousel() {
  return (
    <div className={TestimonialCarouselStyles.container}>
      <HoCarousel
        swipeable={false}
        showStatus={false}
        showArrows={false}
        infiniteLoop={true}
        autoPlay={true}
        stopOnHover={true}
        showThumbs={false}
        transitionTime={800}
        interval={3000}
        className={`aemTestimonialCarousel`}
        showIndicators={false}
      >
        <div className={TestimonialCarouselStyles.slideWrapper}>
          <h4>What they Say</h4>
          <img
            className={TestimonialCarouselStyles.quotes}
            src="../../quote-red.svg"
          />
          <p className={TestimonialCarouselStyles.comments}>
            If you’re looking for a business partner to help with your site,
            just engage Hashout. Hashout sticks to our budget, is mindful of our
            requirements and priorities, and keeps our customer's needs top of
            mind. With Hashout’s help, customer satisfaction with our site is
            greater than ever. ”
          </p>
          <img
            className={TestimonialCarouselStyles.authorImage}
            src="../../beth-pollock.png"
          />
          <p className={TestimonialCarouselStyles.authorName}>Beth Pollock</p>
          <p className={TestimonialCarouselStyles.authorDesignation}>
            Sr. Principal Information Development at Citrix
          </p>
        </div>
      </HoCarousel>
    </div>
  );
}

export default AemTestimonialCarousel;
