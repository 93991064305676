import React from "react";
import HoCarousel from "../../Caroursel";
import AemResourceCarouselStyles from "./AemResourceCarousel.module.scss";
import { Link } from "gatsby";
import "./AemResourceCarousel.scss";

function AemResourceCarousel() {
  return (
    <div className={AemResourceCarouselStyles.resourcesContainer}>
      <div className={AemResourceCarouselStyles.slideTitle}>
        <p>Resource</p>
        {/**<p>
          Welcome to our AEM blogs hub. Here you can access the latest blog
          posts from across the company, perspectives from our executives and
          the full directory.
        </p> */}
        <p>
          <a href="https://hashouttech.com/blog" target="_blank">
            EXPLORE MORE BLOGS
          </a>
          <span>
            <img src="../../RedVector.svg"></img>
          </span>{" "}
        </p>
      </div>
      <HoCarousel
        swipeable={false}
        showStatus={false}
        showArrows={true}
        infiniteLoop={true}
        autoPlay={false}
        stopOnHover={true}
        showThumbs={false}
        transitionTime={800}
        interval={5000}
        className={`aemResourceCarousel`}
        showIndicators={false}
        statusFormatter={(current, total) => `0${current}/0${total}`}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              title="Previous Slide"
              className="control-arrow prev-arrow"
            >
              <img
                className="prev-control-image"
                src="../../carouselNext.svg"
              ></img>
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title="Next Slide"
              className="control-arrow next-arrow"
            >
              <img
                className="next-control-image"
                src="../../carouselNext.svg"
              ></img>
            </button>
          )
        }
      >
        <div className={AemResourceCarouselStyles.carouselContainer}>
          <div className={AemResourceCarouselStyles.cardWrapper}>
            <a
              href="https://hashouttech.com/blog/How-to-Upgrade-Adobe-Experience-Manager-to-AEM-Version-6.5"
              target="_blank"
            >
              <div className={AemResourceCarouselStyles.card}>
                <p>How to Upgrade Adobe Experience Manager to Version 6.5?</p>
                <p>
                  From core library updates to support for newer JDK versions,
                  AEM version 6.5 is a feature-packed upgrade. In this article,
                  we are sharing ...
                </p>
              </div>
            </a>
            <a
              href="https://hashouttech.com/blog/Simplify-Your-AEM-Coding-With-Vault-Syncing-between-Disk-and-Server"
              target="_blank"
            >
              <div className={AemResourceCarouselStyles.card}>
                <p>
                  Simplify Your AEM Coding With Vault:Syncing between Disk and
                  Server
                </p>
                <p>
                  Since it’s inception, Adobe Experience Manager has provided
                  it’s own IDE, CRXDE Lite . Anyone who used it will know the
                  difficulty in ...
                </p>
              </div>
            </a>
            <a
              href="https://hashouttech.com/blog/creating-a-tool-in-adobe-experience-manager"
              target="_blank"
            >
              <div className={AemResourceCarouselStyles.card}>
                <p>Creating a Tool in Adobe Experience Manager</p>
                <p>
                  We have all used the AEM Menu Tool console to access and
                  monitor various functionalities. AEM is known for it versalite
                  ...
                </p>
              </div>
            </a>
          </div>
        </div>
      </HoCarousel>
    </div>
  );
}

export default AemResourceCarousel;
