import React from "react";
import ServiceAndPartnershipStyles from "./ServiceAndPartnership.module.scss";
import ContactPopUp from "../ContactPopUp";

function ServiceAndPartnership(props) {
  const { url = '' } = props;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className={ServiceAndPartnershipStyles.serviceContainer}>
      <div className={ServiceAndPartnershipStyles.serviceBannerWrapper}>
        <div className={ServiceAndPartnershipStyles.serviceBanner}>
          <div className={ServiceAndPartnershipStyles.bannerImage}>
            <img src="../../aem-banner.png"></img>
          </div>
          <div className={ServiceAndPartnershipStyles.bannerTitle}>
            <h4>Adobe Experience Manager on Cloud</h4>
            <p>
              A digital foundation that’s always current, scalable, and
              accessible.
            </p>
            <p onClick={openModal}>
              learn more
              <span>
                <img src="../../Vector.svg"></img>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className={ServiceAndPartnershipStyles.serviceShowCase}>
        <div className={ServiceAndPartnershipStyles.serviceInfo}>
          <div className={ServiceAndPartnershipStyles.hoLogo}>
            <img src="../../Logo.svg"></img>
          </div>
          <p>
            is an Adobe Partner with expertise in Adobe Experience Manager. We
            have helped leading brands transform their online customer journey.
          </p>
          <p onClick={openModal}>
            Start A project
            <span>
              <img src="../../RedVector.svg"></img>
            </span>
          </p>
          <div className={ServiceAndPartnershipStyles.serviceProvide}>
            <p>Our services includes</p>
            <ul>
              <li>Implementation</li>
              <li>Migration</li>
              <li>Maintenance</li>
              <li>Maintenance & Support</li>
              <li>Upgrade</li>
              <li>Cloud Migration</li>
              <li>Integrations</li>
            </ul>
          </div>
        </div>
        <div className={ServiceAndPartnershipStyles.serviceLogoWrapper}>
          <div className={ServiceAndPartnershipStyles.partnerCircleWrapper}>
            <div className={ServiceAndPartnershipStyles.partnerLogo}>
              <img src="../../Adobe-partner.svg"></img>
            </div>
            <div className={ServiceAndPartnershipStyles.circle1}></div>
            <div className={ServiceAndPartnershipStyles.circle2}></div>
            <div className={ServiceAndPartnershipStyles.circle3}></div>
          </div>
        </div>
      </div>
      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let’s Connect"
        subTitle="Fill out this short form, and we’ll get back to you within one
        working day."
        url={url}
        section="start a project"
      />
    </div>
  );
}
export default ServiceAndPartnership;
